import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';

import { Container, Row, Column } from '@common/Grid';
import { Text } from '@common/components/Text';
import Widget from './Widget';
import WidgetDefault from './WidgetDefault';

const Section = styled('div')`
  background-color: ${props => props.backgroundColor};
`;

const ColumnWrapIframe = styled(Column)`
  z-index: 0;
`;

const WidgetBlock = ({ block, ...props }) => {
  const widget = block?.widget ?? '';
  const backgroundColor = block?.backgroundColor ?? '#ffffff';

  return (
    <Section backgroundColor={backgroundColor}>
      <Container py={[6, 8, 8, 10]} px={[1, 4, 2]}>
        <Row justifyContent="center">
          {widget.length > 0 ? (
            <ColumnWrapIframe width={[1, null, 5 / 6, 2 / 3]} px={[0, 1, 2]}>
              <Widget {...props} widget={widget} />
            </ColumnWrapIframe>
          ) : (
            <Column width={1} px={[3, 1, 2]}>
              <WidgetDefault
                href="https://nethouse.ru/about/instructions/vkontakte_vidzhet_dlya_kommentariev"
                title="комментариев ВКонтакте"
              />
            </Column>
          )}
        </Row>
      </Container>
    </Section>
  );
};

WidgetBlock.propTypes = {
  block: PropTypes.shape({
    widget: PropTypes.string,
    backgroundColor: PropTypes.string
  })
};

export default WidgetBlock;
