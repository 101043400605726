import gql from 'graphql-tag';

const GET_FRAGMENT = gql`
  query pageFragment($id: ID) {
    fragmentPayload(id: $id)
      @rest(type: "FragmentPayload", path: "/:id", endpoint: "fragment") {
      fragment @type(name: "FragmentPayloadData") {
        id
        props @type(name: "Props") @edit(as: "Panel") {
          widget
            @edit(
              as: "Code"
              label: "Код виджета"
              docs: "https://nethouse.ru/about/instructions/vkontakte_vidzhet_dlya_kommentariev"
            )
          backgroundColor
            @edit(as: "Color", label: "Цвет фона", value: "#ffffff")
        }
      }
    }
  }
`;

export default GET_FRAGMENT;
