const formattedWidget = props => {
  const widget = props.props?.widget ?? '';
  const blockId = props.rootContext?.blockId ?? '';
  const isId = widget.match(/id="(\S+)\"/);

  if (isId) {
    const widgetId = new RegExp(isId[1], 'gi');
    const widgetIdNew = isId[1] + '_' + blockId;

    return widget.replace(widgetId, widgetIdNew);
  }
  return widget;
};

export { formattedWidget };
