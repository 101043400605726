import React, { Component } from 'react';

class Widget extends Component {
  constructor(props) {
    super(props);
    this.iframe = React.createRef();
  }

  componentDidMount() {
    this.replaceWidget(this.props.widget);
  }

  replaceWidget = widget => {
    while (this.iframe.current.firstChild) {
      this.iframe.current.removeChild(this.iframe.current.firstChild);
    }

    const fragment = document.createRange().createContextualFragment(widget);

    const lib = document.createElement('script');
    lib.src = 'https://vk.com/js/api/openapi.js?159';
    lib.type = 'text/javascript';
    lib.onload = () => {
      while (fragment.childElementCount) {
        this.iframe.current.appendChild(fragment.firstElementChild);
      }
    };
    this.iframe.current.appendChild(lib);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.widget === this.props.widget) {
      return;
    }

    this.replaceWidget(this.props.widget);
  }

  render() {
    if (process.env.SERVER) {
      return null;
    }

    return <div ref={this.iframe} />;
  }
}

export default Widget;
