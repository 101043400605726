import React from 'react';
import { graphql, compose } from 'react-apollo';
import { mapProps } from 'recompose';

import { withFragmentEntitySubscription } from '@common/hoc/withFragmentEntitySubscription';
import GET_FRAGMENT from '../fragment';
import WidgetPanel from './WidgetPanel';
import { formattedWidget } from './FormattedWidget';

const ContainerWithFragmentEntitySubscription = withFragmentEntitySubscription(
  WidgetPanel
);

const ContainerWithFormattedWidget = mapProps(props => {
  return {
    ...props,
    block: { ...props.props, widget: formattedWidget(props) }
  };
})(ContainerWithFragmentEntitySubscription);

const ContainerWithEditableProps = mapProps(props => {
  return {
    ...props,
    ...props.fragment?.fragmentPayload?.fragment
  };
})(ContainerWithFormattedWidget);

const WidgetContainer = compose(
  graphql(GET_FRAGMENT, {
    name: 'fragment',
    options: props => {
      return {
        variables: {
          id: props.fragmentEntity?.id
        },
        errorPolicy: 'ignore'
      };
    }
  })
)(ContainerWithEditableProps);

export default WidgetContainer;
